<template>
    <div>
        <InternalRecipientCategories />
    </div>
</template>

<script>
import InternalRecipientCategories from '@/components/settings/internalRecipientCategories/index.vue';

export default {
    name: 'SettingsInternalRecipientCategories',
    components: {
        InternalRecipientCategories,
    },
};
</script>
